

.mainDiv-login {
    width: 100%;
    height: 100vh;
    max-height: auto;
    min-height: 650px;
    position: relative;
    /* background-color: red; */
}

.mainDiv-login::after {
    content: "";    
    background: linear-gradient(rgba(17, 17, 17, 0.7), rgba(10, 10, 10, 0.8)),url('../../images/home9.jpg');
    background-size:cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
}

.mainLogin-header {
    height: 100px;
    /* background-color: #222; */
    display: flex;
    align-items: center;
    padding: 0 90px;
}

.mainLogin-header > img {
    width: 85px;
    height: 55px;
    cursor: pointer;
}

.mainLogin-body {
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: red; */
}

.mainBody-img {
    width: 350px;
    height: 250px;
}

.mainBody-card {
    height: 420px;
    width: 400px;
    /* padding: 200px; */
    background-color: rgba(41, 38, 38, 0.8);
    border-radius: 5px;
    box-shadow: 0 19px 38px rgba(36, 34, 34, 0.15), 0 15px 12px rgba(31, 30, 30, 0.22);
    border: 2px solid rgb(174,152,37);
}

.loginCard-top {
    height: 40%;
    /* background-color: orange; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardAvatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.btnLogin {
    padding: 7px 0;
}

.btnLogin:disabled {
    cursor: not-allowed;
    color: #555;
    background-color: #444;
    border: 2px solid #222;
}

.loginCard-bottom {
    height: 60%;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    padding: 0 20px;
    /* align-items: center; */
    /* justify-content: space-evenly; */
}

.inputLog {
    background-color: rgba(17, 16, 16, 0.9);
}

.mainDiv-footer {
    width: 100%;
    height: 40px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: orange; */
    position: absolute;
    color: rgb(209, 183, 33) !important;
    font-family: Kiwi Maru;
    font-size: 13px;
    /* padding: 20px 0; */
    letter-spacing: 1.5px;
    word-spacing: 2px;
}

@media only screen and (max-width: 600px) {
    .mainLogin-header {        
        padding: 0 20px;
    }
}

@media only screen and (max-width: 425px) {
    .mainBody-card {
        width: 370px;
        height: 400px;
    }
}

@media only screen and (max-width: 390px) {
    .mainBody-card {
        width: 340px;
        height: 370px;
    }
}

@media only screen and (max-width: 350px) {
    .mainBody-card {
        width: 310px;
        height: 350px;
    }

    .mainLogin-header {        
        padding: 0 10px;
    }
    
}

@media only screen and (max-width: 330px) {
    .mainBody-card {
        width: 300px;
        height: 340px;
    }
}
