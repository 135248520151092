@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru&display=swap");

.mainProjects-div {
  width: 100%;
  background-color: #111;
  padding-bottom: 100px;
  border-bottom: 0.5px groove #999;
}

.mainProject-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ddd !important;
}

.mainProject-title > span:first-child {
  font-family: Kiwi Maru;
  letter-spacing: 3px;
  word-spacing: 10px;
  font-size: 2.4em;
  margin-bottom: 5px;
}

.mainProject-title > span:nth-child(2) {
  font-family: Acme;
  letter-spacing: 2px;
  font-size: 1.3em;
}

.mainProject-body {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.timeLine-item {
  padding: 0 100px;
  height: 400px;
}

.timeLine-title {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  font-family: Acme;
  color: #ddd !important;
}

.timeLine-title > span:first-child {
  text-transform: uppercase;
  letter-spacing: 1.2px;
  word-spacing: 3px;
  font-size: 20px;
  color: rgb(209, 183, 33);
}

.timeLine-title > span:nth-child(2) {
  font-size: 15px;
  letter-spacing: 1.1px;
}

.timeLine-body {
  height: 80%;
  display: flex;
  justify-content: space-between;
  color: #ddd !important;
}

.timeLine-img {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.imgLaptop {
  background: url("../../images/macbook.png");
  background-size: 100% 100%;
  width: 90%;
  max-width: 400px;
  height: 75%;
  display: flex;
  justify-content: center;
}

.laptopInner {
  width: 80.8%;
  margin-left: -2px;
  margin-top: 13px;
  border-radius: 2px;
  overflow: hidden;
}

.timeLine-desc {
  width: 38%;
  overflow: auto;
  font-family: Acme;
  font-size: 16px;
  display: flex;
  text-align: left;
  padding: 0px 2px;
  padding-top: 30px;
  word-spacing: 2px;
}

.carouselItem {
  width: 100%;
  height: 145px;
  border-radius: 5px;
}

.carouselImg {
  width: 100%;
  height: 100%;
}

.slickDiv {
  height: 195px;
  width: 21.7vw;
}

.imgButtons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btn {
  background-color: transparent;
  color: #fff;
  outline: none;
  border: 2px solid rgb(173, 150, 14);
  border-radius: 6px;
  padding: 2px 15px;
  cursor: pointer;
}

.btn:hover {
  transition: 0.7s;
  background-color: rgb(173, 150, 14);
}

:root .ant-timeline-item-tail {
  border-inline-start: 2px solid #ccc;
}

@media only screen and (max-width: 1600px) {
  .timeLine-item {
    padding: 0 20px;
    height: 400px;
  }
}

@media only screen and (max-width: 1100px) {
  .imgLaptop {
    height: 200px;
  }

  .laptopInner {
    height: 120px;
  }
}

@media only screen and (max-width: 1000px) {
  .imgLaptop {
    height: 170px;
  }

  .laptopInner {
    height: 100px;
  }
}

@media only screen and (max-width: 970px) {
  .laptopInner {
    height: 120px;
  }

  .timeLine-item {
    height: auto;
  }

  .timeLine-title {
    margin-bottom: 15px;
  }

  .timeLine-body {
    flex-direction: column;
    align-items: center;
  }

  .timeLine-img {
    width: 350px;
  }

  .slickDiv {
    width: 100%;
  }

  .timeLine-title > span:nth-child(2) {
    font-size: 1.5vw;
    letter-spacing: 1.1px;
  }

  .timeLine-desc {
    width: 100%;
    padding: 10px 10px;
  }

  .imgButtons {
    margin: 20px 0;
  }

  .imgLaptop {
    max-width: 250px;
    height: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .timeLine-desc {
    font-size: 15px;
  }
}

@media only screen and (max-width: 730px) {
  .timeLine-title > span:nth-child(2) {
    font-size: 14px;
    letter-spacing: 1.1px;
  }

  .timeLine-title > span:nth-child(3) {
    font-size: 12px;
    letter-spacing: 1.1px;
    font-family: Acme;
  }
}

@media only screen and (max-width: 600px) {
  .mainProject-title > span:first-child {
    font-size: 5.5vw;
  }

  .mainProject-title > span:nth-child(2) {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 500px) {
  .timeLine-title > span:nth-child(2) {
    font-size: 12px;
  }

  .mainProject-title > span:first-child {
    font-size: 5.3vw;
  }

  .mainProject-title > span:nth-child(2) {
    font-size: 2.8vw;
  }
}

@media only screen and (max-width: 410px) {
  .mainProject-title > span:first-child {
    font-weight: bold !important;
  }

  .mainProject-title > span:nth-child(2) {
    letter-spacing: 1px;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 390px) {
  .timeLine-img {
    width: 320px;
    height: 300px;
    /* background-color: orange; */
  }
}

@media only screen and (max-width: 375px) {
  .mainProject-title > span:first-child {
    font-size: 4.5vw;
  }

  .mainProject-title > span:nth-child(2) {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 360px) {
  .mainProject-title > span:nth-child(2) {
    font-size: 11.5px !important;
  }
  .timeLine-img {
    width: 300px;
    height: 280px;
    /* background-color: orange; */
  }
}

@media only screen and (max-width: 345px) {
  .timeLine-img {
    width: 270px;
    height: 250px;
    /* background-color: orange; */
  }

  .mainProject-title > span:nth-child(2) {
    font-size: 10.3px !important;
  }
}
