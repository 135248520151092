.mainAbout-div {
  width: 100%;
  height: auto;
  min-height: 800px;
  background-color: #111;
  position: relative;
  z-index: 1;
  color: #ddd !important;
  border-bottom: 0.5px groove #999;
  padding: 70px 0;
}

/* .mainAbout-div::before {    
    content: '';
    position: absolute;
    margin-top: -90px;
    border-top: 90px solid transparent;
    border-left: 100vw solid #222;  
    z-index: -99 !important;
    
  } */

.mainAbout-inner {
  height: 100%;
  width: 100%;
  /* margin-top: 100px; */
  /* background-color: aquamarine; */
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* z-index: 99 !important;   */
}

.divider {
  height: 87px;
  width: 100%;
  background-color: #111;
  /* background-color: blanchedalmond; */
}

.aboutInner-left {
  width: 30%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
  /* background-color: red; */
}

.aboutInner-right {
  width: 62%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 40px;
  /* background-color: brown; */
}

.card {
  position: relative;
  top: 0%;
  /* left: 45%; */
  /* transform: translate(-50%, 0%); */
  max-width: 500px;
  width: 90%;
  height: 450px;
  background: #000;
  box-shadow: 5px 10px 15px -8px #888;
}
.card .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background: url("http://1.bp.blogspot.com/-EhPr4LXcywE/Udr594sPHTI/AAAAAAAAAJ4/Tv4y4CBLTPM/s400/Cristina-Otero-2.jpg"); */
  /* background-size: 100% 100%; */
}
.card .image img {
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.card:hover .image img {
  opacity: 0.5;
  transform: translateX(30%); /*100%*/
}
.card .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%; /*100%*/
  height: 100%;
  background: rgb(209, 183, 33);
  transition: 0.5s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}
.card:hover .details {
  transform: perspective(2000px) rotateY(0deg);
}
.card .details .center {
  padding: 20px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.card .details .center h1 {
  margin: 0;
  padding: 0;
  color: #ff3636;
  line-height: 20px;
  font-size: 20px;
  text-transform: uppercase;
}
.card .details .center h1 span {
  font-size: 14px;
  color: #262626;
}
.card .details .center p {
  margin: 10px 0;
  padding: 0;
  color: #262626;
}
.card .details .center ul {
  margin: 10px auto 0;
  padding: 0;
  display: table;
}
.card .details .center ul li {
  list-style: none;
  margin: 0 5px;
  float: left;
}
.card .details .center ul li a {
  display: block;
  background: #262626;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transform: 0.5s;
}
.card .details .center ul li a:hover {
  background: #ff3636;
}

.aboutLeft-item {
  height: 70%;
  width: 85%;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 3px 10px 10px -5px #666;
}

.aboutLeft-avatar {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  position: relative;
}

.aboutLeft-avatar span {
  position: absolute;
  width: 140px;
  height: 140px;
  background-color: rgb(173, 150, 14);
  top: -70px;
  border-radius: 50%;
  overflow: hidden;
}

.aboutAvatar {
  width: 100%;
  height: 100%;
  margin: auto;
}

.aboutLeft-info {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: orange; */
}

.leftInfo-name {
  height: 15%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  /* background-color: red;; */
}

.leftInfo-name span:first-child {
  font-family: Acme;
  font-size: 1.9em;
  word-spacing: 3px;
  letter-spacing: 1.5px;
  color: #222;
  /* text-decoration: underline; */
}

.leftInfo-name span:nth-child(2) {
  font-family: Acme;
  font-size: 15px;
  color: #444;
  letter-spacing: 1.1px;
}

.leftInfo-skills {
  height: 82%;
  /* background-color: red; */
}

.leftSkills-inner {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-image: url("../../images/laptop.png");
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSkills-slick {
  /* display: flex;
    justify-content: space-around;
    align-items: center; */
  /* flex-wrap: wrap; */
  margin-top: -30px;
  height: 80%;
  width: 80%;
  /* background-color: orange; */
}

.leftSkills-img {
  width: 70px;
  height: 55px;
}

.innerRight-line {
  height: 95%;
  width: 10px;
  /* background-color: #444; */
}

.innerRight-about {
  width: 97%;
  height: 95%;
  /* background-color: red; */
}

.rightAbout-one {
  width: 92%;
  height: auto;
  padding: 20px 0px;
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rightAbout-one div:first-child > span {
  font-family: Acme;
  /* font-family: Kiwi Maru; */
  font-size: 23px;
  letter-spacing: 1.5px;
  position: relative;
  color: rgb(173, 150, 14);
  /* z-index: 99; */
}

/* .rightAbout-one > div:first-child > span::after
   {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 80%;
    margin: auto;
    left: -0.5rem;
    right: -0.5rem;
    height: 15px;
    z-index: -1;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
  } */

.rightAbout-one div:nth-child(2) {
  /* font-family: Kiwi Maru; */
  font-family: Acme;
  font-size: 17px;
  letter-spacing: 1.2px;
  padding-top: 10px;
}

.rightAbout-two {
  width: 92%;
  /* height: 27%; */
  /* background-color: blue; */
  padding: 20px 0px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.aboutTwo-title {
  height: 25%;
  font-family: Acme;
  font-size: 23px;
  display: flex;

  /* outline: black; */
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center !important;
  color: rgb(173, 150, 14);
  /* background-color: red; */
}

.aboutTwo-title > span {
  position: relative;
}

/* .aboutTwo-title > span::after
   {
    content: '';
    position: absolute;
    bottom: -3px;
    width: 80%;
    margin: auto;
    left: -0.5rem;
    right: -0.5rem;
    height: 12px;
    z-index: -1;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
  } */

.aboutTwo-inner {
  height: 75%;
  width: 100%;
  display: inline-flex;
  padding-top: 15px;
  /* background-color: red; */
}

.aboutTwo-inner > div:first-child,
.aboutTwo-inner > div:nth-child(2) {
  height: 100%;
  width: 35%;
  /* background-color: orange; */
}

.aboutTwo-inner > div:nth-child(3) {
  height: 100%;
  width: 30%;
}

.aboutTwo-inner > div:first-child > div:first-child {
  height: 20%;
  display: flex;
  align-items: center;
  font-family: Acme;
  font-size: 16px;
  letter-spacing: 1.2px;
  /* background-color: green; */
}

.aboutTwo-inner > div:nth-child(2) > div:first-child {
  height: 20%;
  display: flex;
  align-items: center;
  font-family: Acme;
  font-size: 16px;
  letter-spacing: 1.2px;
  /* background-color: violet; */
}

.aboutTwo-inner > div:nth-child(3) > div:first-child {
  height: 20%;
  display: flex;
  align-items: center;
  font-family: Acme;
  font-size: 13px;
  letter-spacing: 1.2px;
  /* background-color: green; */
}

.aboutTwo-inner > div:first-child > div:nth-child(2),
.aboutTwo-inner > div:nth-child(2) > div:nth-child(2),
.aboutTwo-inner > div:nth-child(3) > div:nth-child(2) {
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  /* background-color: orange; */
}

.aboutTwo-inner > div:nth-child(2) > div:nth-child(2) {
  height: 80%;
  /* background-color: green; */
}

.aboutTwo-inner > div:nth-child(3) > div:nth-child(2) {
  height: 80%;
  /* background-color: violet; */
}

.slickDiv {
  height: 65%;
  width: 100%;
  /* background-color: palegreen; */
}

.slickDiv2 {
  height: 35%;
  width: 100%;
  /* background-color: green; */
  display: inline-flex;
  align-items: center;
}

.slickItem {
  width: 100px;
  height: 80px;
  margin-right: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 6px 7px #999;
  border-radius: 5px;
  /* border: 1px ridge #999; */
  /* margin-right: 10px; */
}

.slickItem-big {
  height: 180px;
  width: 100%;
  /* z-index: 999; */
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightAbout-three {
  width: 92%;
  padding: 10px 0;
  /* height: 48%; */
  /* background-color: burlywood; */
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.rightAbout-three > div:first-child > span {
  font-family: Acme;
  font-size: 1.5em;
  word-spacing: 3px;
  letter-spacing: 1.5px;
  position: relative;
}

/* .rightAbout-three > div:first-child > span::after
   {
    content: '';
    position: absolute;
    bottom: -12px;
    width: 70%;
    margin: auto;
    left: -0.5rem;
    right: -0.5rem;
    height: 30px;
    z-index: -1;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
  } */

.rightAbout-three > div:nth-child(2) {
  font-family: Acme;
  font-size: 16px;
  /* word-spacing: 1.2px; */
  /* background-color: red; */
  letter-spacing: 1px;
  padding: 10px 0px 20px 0px;
}

.rightAbout-three > div:nth-child(3) > span {
  font-family: Acme;
  font-size: 1.4em;
  word-spacing: 3px;
  letter-spacing: 1.5px;
  position: relative;
  /* background-color: red; */
}

/* .rightAbout-three > div:nth-child(3) > span::after
   {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 70%;
    margin: auto;
    left: -0.5rem;
    right: -0.5rem;
    height: 30px;
    z-index: -1;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
  } */

.profileBtns {
  width: 35%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  /* background-color: red; */
}

.aboutInner-bottom {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  padding: 30px 40px;
  /* background-color: red; */
}

.aboutInner-bottom > div:first-child {
  /* background-color: red; */
  /* font-size: 20px; */
  font-family: Acme;
  font-size: 1.8em;
  word-spacing: 3px;
  letter-spacing: 1.5px;
  color: rgb(173, 150, 14);
  padding: 10px 0;
}

.aboutInner-bottom > div:nth-child(2) {
  font-size: 17px;
  font-family: Acme;
  letter-spacing: 1px;
  word-spacing: 2px;
}

.aboutInner-bottom > div:nth-child(3) {
  /* background-color: red; */
  margin-top: 30px;
  width: 100% !important;
  display: inline-flex;
}

.aboutInner-bottom > div:nth-child(3) > span {
  font-family: Acme;
  font-size: 1.4em;
  word-spacing: 3px;
  letter-spacing: 1.5px;
  color: rgb(173, 150, 14);
  margin-right: 20px;
  /* padding: 10px 0; */
}

.aboutInner-bottom > div:nth-child(3) > div > * {
  margin-right: 20px;
  /* margin-bottom: 10px; */
}

.aboutTwo-inner > div:first-child > div:nth-child(2) > *,
.aboutTwo-inner > div:nth-child(2) > div:nth-child(2) > *,
.aboutTwo-inner > div:nth-child(3) > div:nth-child(2) > * {
  margin: 4px;
}

/* .rightAbout-three > div:nth-child(4) > span {
    cursor: pointer;
    background-color: red;
  } */

@media only screen and (max-width: 1270px) {
  .aboutInner-left {
    width: 34%;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  /* height */
  .mainAbout-div {
    height: auto;
    /* background-color: red; */
  }

  .mainAbout-inner {
    height: auto;
    /* background-color: orange; */
  }

  .aboutInner-left {
    /* height: 500px; */
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    /* background-color: #fff; */
  }

  .card {
    margin-top: 20px;
    width: 90%;
    height: 500px;
    /* transform: translate(-50%, 0); */
  }

  .rightAbout-one,
  .rightAbout-two,
  .rightAbout-three {
    height: auto;
    padding: 20px 0;
  }

  .rightAbout-one div,
  .rightAbout-two div,
  .rightAbout-three div {
    height: auto;
  }

  .aboutTwo-inner > div:first-child > div:nth-child(2) > *,
  .aboutTwo-inner > div:nth-child(2) > div:nth-child(2) > *,
  .aboutTwo-inner > div:nth-child(3) > div:nth-child(2) > * {
    margin: 4px;
  }

  .profileBtns {
    height: auto;
    padding: 15px 0;
    /* background-color: red; */
    width: 100%;
    justify-content: flex-start;
  }

  .profileBtns > * {
    /* margin:  */
    margin-right: 20px;
  }

  .rightAbout-three > div:nth-child(3) {
    padding-top: 20px;
    /* background-color: red; */
  }
}

@media only screen and (max-width: 1100px) {
  .card .details {
    width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .aboutInner-left {
    /* background-color: orange; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutInner-right {
    /* background-color: red; */
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .aboutTwo-inner > div:nth-child(3) > div:first-child {
    text-align: center;
  }
}

@media only screen and (max-width: 850px) {
  ul > li {
    margin-bottom: 10px !important;
  }

  .card .details {
    width: 95%;
  }
}

@media only screen and (max-width: 830px) {
  .aboutTwo-inner {
    flex-direction: column;
    /* background-color: orange; */
  }

  .aboutTwo-inner > * {
    width: 100% !important;
    margin-bottom: 15px;
    /* background-color: rgb(153, 149, 149); */
  }

  .aboutInner-left {
    width: 40%;
    /* background-color: red; */
  }

  .aboutInner-right {
    width: 60%;
  }
}

@media only screen and (max-width: 810px) {
  .mainAbout-inner {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    /* background-color: red; */
    padding: 0 15px;
    flex-direction: column;
  }

  .aboutInner-right {
    width: 100%;
    margin-top: 30px;
    padding-left: 20px;
  }

  .aboutInner-left {
    width: 100%;
    padding-right: 0;
    /* background-color: orange; */
  }

  .card {
    margin-top: 50px !important;
    width: 350px !important;
    left: 0;
    transform: translate(0%, 0%);
  }

  .card .details {
    width: 70%;
  }

  .aboutTwo-inner {
    flex-direction: row;
    /* background-color: orange; */
  }

  /* .aboutTwo-inner > * {
      width: 100% !important; 
      margin-bottom: 15px;
      /* background-color: rgb(153, 149, 149); */
}

@media only screen and (max-width: 660px) {
  .aboutInner-bottom > div:nth-child(3) {
    /* background-color: red; */
    flex-direction: column;
  }

  .aboutInner-bottom > div:nth-child(3) > span {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 510px) {
  .aboutInner-bottom > div:nth-child(1) {
    font-size: 4.5vw;
  }

  .aboutInner-bottom > div:nth-child(2) {
    font-size: 15.5px;
  }

  .aboutInner-bottom > div:nth-child(3) > span {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 480px) {
  .rightAbout-one > div:first-child > span,
  .rightAbout-two > div:first-child,
  .rightAbout-three > div:first-child > span {
    margin-top: 20px;
    font-size: 5.5vw !important;
  }
}

@media only screen and (max-width: 465px) {
  .aboutTwo-inner {
    flex-direction: column;
    /* background-color: orange; */
  }

  .aboutInner-bottom {
    padding: 0 15px;
  }

  .rightAbout-one > div:first-child > span,
  .rightAbout-two > div:first-child,
  .rightAbout-three > div:first-child > span {
    font-size: 5.2vw !important;
  }

  .aboutTwo-inner > div:nth-child(3) > div:first-child {
    font-size: 15px;
  }
}

@media only screen and (max-width: 425px) {
  .rightAbout-one > div:nth-child(2),
  .rightAbout-three > div:nth-child(2) {
    font-size: 15.5px;
  }

  .aboutInner-bottom > div:nth-child(2) {
    font-size: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .card .details {
    width: 90% !important;
  }

  .aboutInner-bottom > div:nth-child(2) {
    font-size: 14.5px;
  }
}

@media only screen and (max-width: 320px) {
  .profileBtns {
    /* background-color: red; */
    /* width: 100%; */
    justify-content: space-around;
    /* margin: 0; */
  }

  .profileBtns > * {
    margin: 0;
  }
}
