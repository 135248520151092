@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap");

/* *{
    background-color: #f1f1f1;
} */

.mainPage {
  width: 100%;
  /* max-width: 1700px; */
  height: auto;
  overflow: hidden;
  /* background-color: #222;   */
  /* z-index: -2 !important;   */
}

.mainHome-div {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  /* background-color: rgb(46, 44, 44); */
  /* position: relative; */
  display: block;
}

.mainHome-div::after {
  content: "";
  /* background: linear-gradient(rgba(10, 10, 10, 0.8), rgba(10, 10, 10, 0.9)),url("../../images/background.jpg"); */
  /* background-color: #111; */
  background: linear-gradient(rgba(17, 17, 17, 0.4), rgba(10, 10, 10, 0.4)),
    url("../../images/background.jpg");
  background-size: 100% 100%;
  /* background-attachment: scroll; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}

.mainHome-header {
  height: 20%;
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  z-index: 99;
  /* transition: 0.2s; */
  /* position: fixed; */
}

.mainHead-inner {
  width: 95%;
  height: 100%;
  /* background-color: brown; */
  display: inline-flex;
}

.mainHead-left {
  width: 50%;
  height: 100%;
  /* background-color: orange; */
}

.mainHead-right {
  width: 50%;
  height: 100%;
  /* background-color: aliceblue; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mainHead-rightInner {
  width: 47vw;
  height: inherit;
  /* background-color:red; */
  display: inline-flex;
}

.headLeft-span {
  width: 200px;
  height: 100%;
  /* background-color:aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainHead-logo {
  width: 85px;
  height: 55px;
  cursor: pointer;
}

.mainHead-right span {
  width: 25%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Kiwi Maru", serif !important;
}

.mainHead-right a {
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #f1f1f1;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  /* background-color: red; */
}

.mainHead-right a::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(173, 150, 14);
  transition: width 0.3s;
  color: rgb(173, 150, 14);
}

.mainHead-right a:hover::after {
  /* color: rgb(173, 150, 14); */
  width: 100%;
  transition: width 0.3s;
}

.mainHead-right a:focus::after {
  /* color: rgb(173, 150, 14); */
  width: 100%;
  transition: width 0.3s;
}

.mainHead-right a:hover {
  color: rgb(173, 150, 14);
  font-weight: bold;
}

.mainHead-right a:focus {
  color: rgb(173, 150, 14);
  font-weight: bold;
  /* border-bottom: 2px solid rgb(173, 150, 14);
    transition: 0.5s; */
}

.mainHome-body {
  width: 100%;
  height: 80%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center;  */
}

.mainBody-inner {
  /* margin-top: 50px; */
  width: 100%;
  height: 75%;
  font-family: "Kiwi Maru";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* align-items: center; */
  /* background-color:#111; */
  /* border: 3px solid #ddd; */
  /* box-shadow: 0 19px 38px rgb(36, 34, 34), 0 15px 12px rgba(22, 22, 22, 0.22); */
  /* border-radius: 10px; */
}

.mainBody-footer {
  width: 100%;
  height: 25%;
  /* background-color: antiquewhite; */
  padding-left: 100px;
}

.mainFoot-inner {
  width: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */
}

.mainFoot-inner > a {
  margin: 0;
  text-decoration: none;
  color: rgb(173, 150, 14);
}

.mainFoot-inner > a:hover {
  color: rgb(173, 150, 14);
}

.mainFoot-inner > * {
  font-size: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  /* color:rgb(173, 150, 14); */
}

.mainInner-one {
  /* height: 30%; */
  /* background-color: orange;     */
  /* padding: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.7em;
    word-spacing: 5px;
    letter-spacing: 3px;
    color: rgb(173, 150, 14);
    transform: scaleY(1.2);
    font-weight: 600; */
  width: 450px;
  color: #ddd;
  font-size: 15px;
  padding-left: 80px;
  letter-spacing: 5px;
  font-family: "Kiwi Maru", "sans-serif";
  /* background-color: #222; */
}

/* .mainInner-one a {
    text-decoration: none;
    color: rgb(173, 150, 14);
    
}

.mainInner-one a:hover {
    animation: shake 0.7s;
    color: rgb(173, 150, 14);
    animation-iteration-count: infinite;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  } */

.mainInner-two {
  /* background-color: red; */
  /* height: 20%; */
  /* padding: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center; 
    letter-spacing: 3px;
    color: #fff; 
    font-size: 2.2em; */
  /* border-top: 1px solid; */
  /* font-weight: bold; */
  width: 450px;
  font-size: 5em;
  font-weight: 100;
  padding: 15px 0;
  text-shadow: 5px 5px 0px rgba(43, 40, 40, 0.9);
  letter-spacing: 15px;
  text-align: center;
  color: rgb(173, 150, 14);
  transform: scaleY(1.4);
  /* font-weight: bold; */
  font-family: "Rubik Mono One", sans-serif;
  /* background-color: orange; */
}

.mainInner-divider {
  width: 60%;
  height: 2px;
  background-color: #ccc;
  margin: auto;
}

.mainInner-three {
  width: 450px;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  font-size: 12px;
  color: #fff;
  /* padding-bottom: 5px; */
  letter-spacing: 4px;
  font-family: "Kiwi Maru", "sans-serif";
  /* flex-direction: column; */
  /* height: 20%; */
  /* background-color: orchid; */
  /* padding: 10px 0px 15px 0px; 
      font-style: italic;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 2px;
      color: #fff; */
}

.mainInner-four {
  /* height: 29%; */
  padding: 15px 0px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  /* background-color: red; */
}

.mainInner-four:hover {
  text-decoration: none;
  color: #fff;
}

.mainInner-four span:first-child {
  font-size: 17px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.dropDownIcon {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

.responsiveDrawer {
  display: none;
}

::-webkit-scrollbar {
  width: 9px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(173, 150, 14);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(148, 127, 11);
}

.mainHome-foot {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  /* padding-bottom: 20px; */
  /* background-color: #222;   */
}

.mainHome-foot > span {
  position: fixed;
  /* background: red; */
  /* margin-top: -10px; */
  bottom: 20px;
  right: -40px;
  /* display: flex;
    align-items: flex-end;
    justify-content: flex-end; */
  z-index: 2 !important;
}

.mainHome-foot span {
  margin-right: 30px;
  cursor: pointer;
  /* background: red; */
}

.mainHome-foot span:hover {
  animation: shake 1s;
  color: rgb(173, 150, 14);
  animation-iteration-count: infinite;
}

@media only screen and (max-width: 1200px) {
  .mainHead-left {
    width: 30%;
    /* background-color: red; */
  }

  .mainHead-right {
    width: 70%;
    /* background-color: orange; */
  }

  .mainHead-rightInner {
    width: 60vw !important;
    /* background-color: orchid; */
  }

  /* .mainInner-three {
          font-size: 1.3vw;
      }       */
}

@media only screen and (max-width: 950px) {
  .mainHome-div {
    height: 100vh;
    /* max-height: 700px; */
  }

  .mainHead-left {
    width: 20%;
  }

  .mainHead-right {
    width: 80%;
  }

  .mainHead-rightInner {
    width: 70vw !important;
  }

  .headLeft-span {
    width: 100%;
  }

  .mainBody-footer {
    padding-left: 90px;
  }

  /* .mainInner-four > span {
          font-size: 1.3vw !important;
      }

      .mainInner-one {
          font-size: 3.7vw;
          background-color: orange;
      }

      .mainInner-two {
          font-size: 3vw;
          background-color: red;
      } */
}

@media only screen and (max-width: 770px) {
  .mainHead-inner {
    width: 100%;
  }

  /* .mainInner-four > span {
        font-size: 12px !important;
    } */

  .mainHead-right {
    justify-content: flex-end;
  }

  .mainBody-footer {
    padding-left: 50px;
  }

  .mainHead-rightInner {
    width: 77vw !important;
  }

  .mainHead-right a {
    font-size: 16px;
  }

  /* .mainInner-three {
          font-size: 1.3vw;
          text-align: center;
          background-color: palegoldenrod;
      }

      .mainBody-inner {
          width: 100%;
      } */
}

@media only screen and (max-width: 630px) {
  .mainHead-right,
  .mainHead-left {
    display: none;
  }

  .mainBody-footer {
    padding-left: 20px;
    /* background-color: red; */
  }

  .mainBody-inner {
    height: 60%;
    /* background-color: orange; */
  }

  /* .mainInner-three {
        font-size: 1.6vw;
        text-align: center;
        align-items: flex-start;
        height: 15%;
        background-color: lawngreen;
    }

    .mainInner-four {
        background: red;
        justify-content: flex-start;
    }
    */

  .mainInner-three {
    /* background-color: red; */
  }

  .mainInner-three > span {
    /* background-color: orange; */
  }

  .mainHome-div {
    /* background-color: royalblue; */
  }

  .mainHome-body {
    height: 100%;
    /* padding-top: 100px; */
    /* background-color: rosybrown; */
  }

  .mainBody-inner {
    padding-top: 50px;
    /* background-color: orange; */
  }

  .mainBody-footer {
    /* background-color: red; */
  }

  .responsiveDrawer {
    display: block;
    width: 100%;
    height: 70px;
    z-index: 99 !important;
    background-color: #111;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 2px #222;
    position: relative;
  }

  .mainHead-logo {
    width: 70px;
    height: 40px;
  }

  .menuDiv {
    font-size: 25px;
    color: #fff !important;
    /* background-color: orange; */
  }

  .drawerItem-div {
    position: absolute;
    width: 100%;
    transition: all 0.3s ease-in-out;
    top: 70px;
    left: 0px;
    background-color: #111;
    box-shadow: 1px 1px 2px #222;
    overflow: hidden;
    /* padding-right: 30px;  */
  }

  .drawerItem {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 10px;

    /* background-color: red; */
  }

  .drawerItem > span {
    /* background-color: orange; */
    font-size: 16.5px;
    color: #fff;
  }

  .drawerItem > span > a {
    color: #fff;
    text-decoration: none;
  }

  .drawerItem > span > a:hover {
    color: rgb(209, 183, 33);
  }

  .drawerItem > span > a:focus {
    font-weight: bold;
    color: rgb(209, 183, 33);
  }

  .mainHome-header {
    position: fixed;
  }
}

@media only screen and (max-width: 560px) {
  /* .mainInner-one {
        font-size: 20px;
    } */
}

@media only screen and (max-width: 490px) {
  /* .mainHome-body {
        padding-top: 0px;
        /* background-color: red; */
  /* background: rgb(148, 127, 11); */
  /* } */

  .mainBody-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: red; */
    /* padding-top: 100px; */
    width: 100%;
    padding-left: 20px;
  }

  .mainInner-two {
    font-size: 4em;
  }

  /* .mainInner-one {
        height: auto;
        font-size: 4vw !important;
        justify-content: flex-start;          
        overflow: hidden; Ensures the content is not revealed until the animation
        border-right: .15em solid orange; The typwriter cursor
        white-space: nowrap; Keeps the content on a single line            
        animation: 
            typing 3.5s steps(30, end),
            blink-caret .5s step-end infinite;
        padding-bottom: 5px;
        } */

  /* The typing effect */
  /* @keyframes typing {
        from { width: 0 }
        to { width: 90% }
        } */

  /* The typewriter cursor effect */
  /* @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: orange }
        } */
  /* } */

  /* .mainInner-two {
        height: auto;
        padding-bottom: 7px;
        justify-content: flex-start;
          background: pink;
      }

      .mainInner-three {
        justify-content: flex-start;
        text-align: left;
        height: auto;
        padding: 0px 0 15px 0;
        font-size: 8.5px;
        font-style: italic;
          background: purple;
      }

      .mainInner-four {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        width: auto;
        background: rebeccapurple;
      }

      .mainInner-four > span {
        font-size: 10px !important;
      } */

  .mainInner-divider {
    display: none;
  }

  .dropDownIcon {
    margin-left: 10px;
    /* font-size: 13px !important; */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    animation-name: bounce-2;
    animation-timing-function: linear;
  }

  @keyframes bounce-2 {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@media only screen and (max-width: 420px) {
  /* .mainInner-one {
          font-size: 3.5vw !important;
      }

      .mainInner-two {
          font-size: 11.5px  !important;
      }

      .mainInner-three {
          font-size: 8px !important;
      }

      .mainInner-four > span {
          font-size: 9px !important;
      } */

  .mainHome-body {
    height: 100vh;
    justify-content: center;
    /* background-color: green; */
  }

  .mainInner-one {
    padding-left: 100px;
    font-size: 12px;
  }

  .mainInner-two {
    font-size: 3em;
  }

  .mainInner-three {
    padding-right: 100px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 375px) {
  /* .mainInner-one {
          font-size: 3vw !important;
      } */

  /* .mainHome-foot {
          position: fixed;
          bottom: 0px;          
          background-color: transparent;
      }

      .mainHome-foot > span {
          z-index: 99 !important;
      } */
}

@media only screen and (max-width: 350px) {
  /* .mainInner-one {
        font-size: 3.5vw !important;
    }
    .mainInner-two {
        font-size: 10px  !important;
    } */
}
